// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: '',
  integrationHubConfigurationApiScopes: [],
  legacyConnectorsConfigApiUrl: '',
  legacyConnectorsConfigApiScopes: [],
  integrationHubApiUrl: '',
  integrationHubApiScopes: [],
  integrationHubLoggingApiUrl: '',
  integrationHubLoggingApiScopes: [],
  datexAlertApiUrl: '',
  datexAlertApiScopes: [],
  datexAlertRecommndedSolutionApiUrl: '',
  datexAlertRecommndedSolutionApiScopes: [],
  kustoQueryApiUrl: 'https://ca-integrationhub-kustoquery-api.victoriousmeadow-f970156f.eastus.azurecontainerapps.io/',
  kustoQueryApiScopes: ['api://709bda71-a43e-4070-b6c7-dcc72df15a01/.default'],
  footPrintAdaptorApiUrl: '',
  footPrintAdaptorApiScopes: [],
  shopifyAdaptorApiUrl: '',
  shopifyAdaptorApiScopes: [],
  wooCommerceAdaptorApiUrl: '',
  wooCommerceAdaptorApiScopes: [],
  bigCommerceAdaptorApiUrl: '',
  bigCommerceAdaptorApiScopes: [],
  mesciusLicenseKey: '*.wavelength.host,232864259645381#B14TeF5RIp4MypGOpdlYCFnUtZlMZhkRwJUd5E4Z5wUY4hTR8FDSZdmeqhTSXNXM5YGR6FWM6YzVJdTS0JUaslEbWhFb5czdt5EaFdHckRVQ5RFRH3yM73yLXhGeMpWeFZ4ZShmdIFGVrYWMDNWS6hHdVl7S7U5Z5skdqF4UxpkRxE4NChGa5o5L5U4bBpkMQlzZrR4Q9ETdZBlb8l4d0hncSF4YQlWMtJkdhlWRrYTNplWcylleRZXRwlWTWxWcQxWelh6VMhEcatEMHNDdIFFb65WUXFUOMJkYLJWQZJDOCR7QaZTVQFEUmljSnBFWiojITJCLiQTNwQUN4kjNiojIIJCLzgTO9QjMyYzM0IicfJye35XX3JiVMtkTiojIDJCLiUjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiIxATNyITMggjMwEDNyAjMiojI4J7QiwiI4N7bo9Ca4dmblxWZ6F6duoiI0IyctRkIsICelRXYEJiOiEmTDJCLiEDOzUDN6kTNyQjN8IzMyIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRp5U7UUW4Q6QhlUMKJVaI5Ee9lDVh5kS4UXRPh7T6I4LwVXSrZDa4ZVbLZWNnV6aUxGU8gzSrVXWIhzVC56c7AzbjhjWHNTNBVDTuVmYKpFOTR5crJldUBHSXJUZSZ7QEdzUI5zNKx',
  ediLogicAppTriggerUrl: 'https://prod-39.eastus.logic.azure.com/workflows/3c4de442557e47e6a1cea6ff8b17201a/triggers/manual/paths/invoke/%7BAction%7D?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SeL8gpRdxvgdTvKeBzVZlEA4FVuU_UgviUEawmCR02c',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-test.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: 'https://test-as2.wavelength.host/AS2Ingress',
  ediPrivate: 'https://wavelength-edi-test.eastus.cloudapp.azure.com/AS2Ingress',
  datexDefaultAlertMessageTemplate: 'https://datexintegrationhub.blob.core.windows.net/datex-alert-services/html-email-templates/IntegrationHubBigEcommerceOrderUpdate.html'
};
